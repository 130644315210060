(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("PSLIBCore"), require("PSLIBPromise"));
	else if(typeof define === 'function' && define.amd)
		define("Request", ["PSLIBCore", "PSLIBPromise"], factory);
	else if(typeof exports === 'object')
		exports["Request"] = factory(require("PSLIBCore"), require("PSLIBPromise"));
	else
		root["PSLIB"] = root["PSLIB"] || {}, root["PSLIB"]["Request"] = factory(root["PSLIB"], root["PSLIB"]["Promise"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 