import PSLIBCore_import from 'pslib-core';
import Promise_import from 'pslib-promise';

// Needed for IE8, for some reason the import is undefined
const PSLIBCore = PSLIBCore_import || global.PSLIB;
const Promise = Promise_import || global.PSLIB.Promise;

// Remove pssession from queryString 
const sanitizePSSessionQueryString = function(args) {
	// Mantis #27729 - remove pssession from queryArgs
	if (args.url) {
		var parsed_uri = PSLIBCore.util.parseUri(args.url),
		deparamFunc = (PSLIBCore.util.deparam)? PSLIBCore.util.deparam : Request.deparam,
		queryArgs = deparamFunc(parsed_uri.query);

		// Remove this
		if (queryArgs.pssession) {
			args.data = args.data || {};

			var oldType = args.type || "get";
			args.type = "post";

			//args.headers["Authorization"] = "PSSession "+queryArgs.pssession;
			args.data["pssession"] = queryArgs.pssession;
			args.data["_method"] = oldType;

			delete(queryArgs.pssession);
			args.url = ((parsed_uri.protocol && parsed_uri.protocol.length > 0) ? 
				parsed_uri.protocol + "://" + parsed_uri.host+ ((parsed_uri.port && parsed_uri.port.length > 0) ? ":" + parsed_uri.port : '') : 
				"")+parsed_uri.path;
			// Manually strip out pssession, because jive uses filter=&filter= which can't be easily parsed and reconstructed
			var query = (typeof(parsed_uri.query) === "string"? parsed_uri.query : "");
			query = query.replace(/^pssession\=[^\&]+\&/, "");
			query = query.replace(/^pssession\=[^\&]+$/, "");
			query = query.replace(/([\&\?]+)pssession\=[^\&]+\&/, "$1");
			query = query.replace(/([\&\?]+)pssession\=[^\&]+$/, "$1");

			if (query && query.length > 0) {
				args.url = args.url + "?"+query;
			}
		}
	}

	return args;
};

const Request = function(args) {
	args = (typeof(args) != "object")? {} : args;
	args.log = (typeof(args.log) != "boolean")? false : args.log;
	args.type = (typeof(args.type) != "string")? "get" : args.type.toLowerCase();
	args.data = (typeof(args.data) == "undefined")? null : args.data;
	args.headers = (typeof(args.headers) != "object")? {} : args.headers;
	// if headers passed inside options add them too
	if (args?.options?.headers) {
		args.headers = {...args.headers, ...args.options.headers};
	}
	args.crossDomain = (typeof(args.crossDomain) == "undefined")? (function() {
		var parsed = PSLIBCore.util.parseUri(args.url.replace(/@/,'')),
		parsed_domain = parsed.host.replace(/^[^.]+\./g, ""),
		parsed_homesite = PSLIBCore.util.parseUri(PSLIBCore.appdata.config.homesite),
		parsed_homesite_domain = parsed_homesite.host.replace(/^[^.]+\./g, ""),
		is_crossdomain = parsed.host == parsed_homesite.host; // Should not care about protocol - only that the request is back to our homesite - which supports crossDomain in http or https
		// Either already detected as cross-domain, or will be detected if root domains match
		is_crossdomain = is_crossdomain || (
			parsed_homesite_domain == parsed_domain &&
			(
				parsed.host.match(/^[tb]{1}[0-9]{1}[^0-9]+/) !== null ||
				parsed.host.match(/^vote\.pollstream\.com/) !== null ||
				parsed.host.match(/^stage[0-9]+\.pollstream\.com/) !== null ||
				parsed.host.match(/^stage[0-9]+-vote\.pollstream\.com/) !== null ||
				parsed.host.match(/^next\./) !== null ||
				parsed.host.match(/^testing\./) !== null ||
				parsed.host.match(/^hotfix\./) !== null ||
				parsed.host.match(/^staging\./) !== null ||
				((parsed.host.match(/-dev\./) !== null || parsed.host.match(/-dev-vote\./) !== null) && parsed.port === parsed_homesite_domain.port)
			)
		);

		return is_crossdomain;
	})() : args.crossDomain;
	var defer = Promise.defer();

	// GET Requests need argument data appended as query string to URL
	if (args.type == "get" && typeof args.soap == "undefined" && args.url) {
		// Additional data was passed - need to put that into request
		var newUrl = Request.getUrlWithQueryString(args.url, args.data);

		// IE cannot handle GET urls over 2,083 characters in length
		if (
			(
				PSLIBCore.browser &&
				PSLIBCore.browser.browser == "msie" &&
				newUrl.length > 2000
			) ||
			newUrl.length > 5000
		) {
			if (!args.data) {
				args.data = {};
			}
			try {
				// Shorten up the original url
				var parsed_uri = PSLIBCore.util.parseUri(args.url),
				query = Request.deparam(parsed_uri.query),
				url = ((parsed_uri.protocol && parsed_uri.protocol.length > 0) ? parsed_uri.protocol+"://"+parsed_uri.host : "")+parsed_uri.path;
				if (url.length > 0) {
					args.url = url;
				}
				args.data = PSLIBCore.util.deepMerge(args.data, query);
			} catch(e) {
			}
			args.type = "post";
			args.data["_method"] = "get";
		} else {
			args.url = newUrl;
			args.data = {};
		}
	}

	var timestamp = parseInt(new Date().getTime() / 1000),
        xhr = (typeof XMLHttpRequest !== 'undefined' ? new XMLHttpRequest() : null),
        preferXdrRequest = Request.preferXdrRequest || false,
        xhrSupportCors = (!preferXdrRequest && xhr && typeof(xhr.withCredentials) !== "undefined"),
	    request;

    // normal code path that is using regular XMLHttpRequest
    // ie. proper CORS support, request headers, etc.
	if (xhrSupportCors || !args.crossDomain) {
		var postData = null,
		headers = {};
		// Additional data was passed - need to put that into request
		if (args.type == "get" &&
			typeof args.soap == "undefined") {
			args.url = Request.getUrlWithQueryString(args.url, args.data);
			args.data = {};
		}

		request = Request.ajax(args).then(function(response) {
			defer.resolve(response);
			return response;
		}, function(response) {
			defer.reject(response);
			return response;
		});
    // fallback CORS way for IE <11 or very old browsers
    // code is inefficient, passing request header is probably broken, not well maintained
	} else {
		request = Request.xdr(args).then(function(response) {
			defer.resolve(response);
			return response;
		}, function(response) {
			defer.reject(response);
			return response;
		});
	}

	if (args.log) {
		request.then(
			function(response) {
				Request.Logger.log({
					timestamp: timestamp,
					args: JSON.stringify(args),
					responseText: response.responseText,
					headersText: response.headersText
				});
			}
		);
	}

	return defer.promise;
};

Request.Logger = (function() {
	var _log = [];
	return {
		getLog: function() {
			return _log;
		},
		log: function(datum) {
			_log.push(datum);
		}
	}
})();

Request.sanitizePSSessionQueryString = sanitizePSSessionQueryString;

// Pass a url, pass some query string data, get back a properly formatted url with query string data (even if your URL has query string already)
Request.getUrlWithQueryString = function(url, data, args) {
	url = (typeof(url) == "string")? url : "";
	data = (typeof(data) == "undefined")? {} : data;
	args = (typeof(args) != "object")? {} : args;
	var url_params = PSLIBCore.util.getParamsFromUrl(url), temp;
	url = url.split("?");

	// Passed a DOM object - preprocess this into an object
	if (PSLIBCore.util.isDOMElement(data)) {
		data = PSLIBCore.dom.toJson(data, args.attr);
	}

	if (PSLIBCore.util.isArray(data)) {
		for(var i = 0; i < data.length; i++) {
			if (PSLIBCore.util.isArray(data[i]) && data[i].length > 0) {
				temp = { key : data[i].shift() };
				if (data[i].length > 0) {
					temp["value"] = data[i][0];
				}
				url_params.push(temp);
			}
			else if (typeof(data[i]) == "object" && data[i].key) {
				temp = { key : data[i].key };
				if (data[i].value !== undefined) {
					temp["value"] = data[i].value;
				}
				url_params.push(temp);
			}
		}
	}
	else if (typeof(data) == "object") {
		for(var k in data) {
			var foundAtIndex = false;
			for(var j = 0; j < url_params.length; j++) {
				if (url_params[j].key === k) {
					foundAtIndex = j;
					break;
				}
			}
			// Data should overwrite duplicate keys if already in url_params
			if (foundAtIndex !== false) {
				url_params[foundAtIndex] = { key : k, value : data[k] };
			}
			else {
				url_params.push({ key : k, value : data[k] });
			}
		}
	}

	if (url_params.length > 0) {
		var part = null, parts = [];
		for(var i = 0; i < url_params.length; i++) {
			part = {};
			part[url_params[i].key] = url_params[i].value;
			parts.push(Request.param(part, {"no_replace_spaces":true}));
		}
		return url[0]+"?"+parts.join("&")+((typeof(data) == "string")? "&"+data : "");
	}
	else if (typeof(data) == "string") {
		return url[0]+"?"+data;
	}
	return url[0];
};

/**
	* Converts a paramatized url string into a JSON object
	* E.g.
	* Request.deparam("a=1&b%5Bc%5D%5B%5D=1&b%5Bc%5D%5B%5D=2&b%5Bc%5D%5B%5D=3");
	* --> {
	*			a: 1,
	*			b: {
	*				 c: [1,2,3]
	*			}
	*		}
	*/
Request.deparam = function(params, coerce) {
	var obj = {},
	coerce_types = { 'true': !0, 'false': !1, 'null': null },
	parts = params.replace(/\+/g, ' ').split('&');

	// Iterate over all name=value pairs.
	//$.each( params.replace( /\+/g, ' ' ).split( '&' ), function(j,v) {
	for(var j = 0; j < parts.length; j++) {
		var v = parts[j],
		param = v.split( '=' ),
		key = decodeURIComponent(param[0]),
		val,
		cur = obj,
		i = 0,

		// If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
		// into its component parts.
		keys = key.split( '][' ),
		keys_last = keys.length - 1;

		// If the first keys part contains [ and the last ends with ], then []
		// are correctly balanced.
		if ( /\[/.test( keys[0] ) && /\]$/.test( keys[ keys_last ] ) ) {
			// Remove the trailing ] from the last keys part.
			keys[ keys_last ] = keys[ keys_last ].replace( /\]$/, '' );

			// Split first keys part into two parts on the [ and add them back onto
			// the beginning of the keys array.
			keys = keys.shift().split('[').concat( keys );

			keys_last = keys.length - 1;
		} else {
			// Basic 'foo' style key.
			keys_last = 0;
		}

		// Are we dealing with a name=value pair, or just a name?
		if (param.length === 2) {
			val = decodeURIComponent( param[1] );

			// Coerce values.
			if (coerce) {
				val = val && !isNaN(val)            ? +val              // number
					: val === 'undefined'             ? undefined         // undefined
					: coerce_types[val] !== undefined ? coerce_types[val] // true, false, null
					: val;                                                // string
			}

			if (keys_last) {
				// Complex key, build deep object structure based on a few rules:
				// * The 'cur' pointer starts at the object top-level.
				// * [] = array push (n is set to array length), [n] = array if n is
				//   numeric, otherwise object.
				// * If at the last keys part, set the value.
				// * For each keys part, if the current level is undefined create an
				//   object or array based on the type of the next keys part.
				// * Move the 'cur' pointer to the next level.
				// * Rinse & repeat.
				for ( ; i <= keys_last; i++ ) {
					key = keys[i] === '' ? cur.length : keys[i];
					cur = cur[key] = i < keys_last
						? cur[key] || ( keys[i+1] && isNaN( keys[i+1] ) ? {} : [] )
						: val;
				}

			} else {
				// Simple key, even simpler rules, since only scalars and shallow
				// arrays are allowed.
				if (PSLIBCore.util.isArray( obj[key] )) {
					// val is already an array, so push on the next value.
					obj[key].push(val);

				} else if ( obj[key] !== undefined ) {
					// val isn't an array, but since a second value has been specified,
					// convert val into an array.
					obj[key] = [obj[key], val];

				} else {
					// val is a scalar.
					obj[key] = val;
				}
			}

		} else if ( key ) {
			// No value was defined, so set something meaningful.
			obj[key] = coerce
				? undefined
				: '';
		}
	};

	return obj;
};

/**
* Turns JSON data into query string parameters - jQuery style
*
* E.g. 	Request.param({
*			a: 1,
*			b: {
*				 c: [1,2,3]
*			}
*		})
*	--> a=1&b%5Bc%5D%5B%5D=1&b%5Bc%5D%5B%5D=2&b%5Bc%5D%5B%5D=3
*/
Request.param = (function() {
	var r20 = /%20/g,
	rbracket = /\[\]$/,
	buildParams = function(prefix, obj, add, attr) {
		if (PSLIBCore.util.isDOMElement(obj)) {
			obj = PSLIBCore.dom.toJson(obj, attr);
		}
		var name, v;
		if (PSLIBCore.util.isArray( obj )) {
			// Serialize array item.
			for(var i = 0; i < obj.length; i ++) {
				v = obj[i];
				if (rbracket.test( prefix )) {
					add( prefix, v );
				} else {
					buildParams( prefix + "[" + ( typeof v === "object" ? i : "" ) + "]", v, add, attr );
				}
			}
		}
		else if (typeof(obj) === "object") {
			// Serialize object item.
			for ( name in obj ) {
				buildParams( prefix + "[" + name + "]", obj[ name ], add, attr );
			}
		}
		else {
			// Serialize scalar item.
			add( prefix, obj );
		}
	};

	return function(a, opts) {
		if (typeof opts != "object") {
			opts = {};
		}
		// Sorts the query string parameters alphabetically
		opts.sort = ((typeof(opts.sort) == "boolean") ? opts.sort : false);

		if (PSLIBCore.util.isDOMElement(a)) {
			a = PSLIBCore.dom.toJson(a, opts.attr);
		}

		var prefix, s = [],
		add = function( key, value ) {
			// If value is a function, invoke it and return its value
			try {
				value = PSLIBCore.util.isFunction( value ) ? value() : ( value == null ? "" : value );
			} catch(e) {
				value = null;
			}

			s[ s.length ] = encodeURIComponent( key ) + "=" + encodeURIComponent( value );
		};

		if (typeof opts.no_replace_spaces == "undefined") {
			opts.no_replace_spaces = false;
		}
		if (!PSLIBCore.util.isArray(a)) {
			var data = [];

			for (var key in a) {
				data.push({key: key, value: a[key]});
			}
			if (opts.sort) {
				data.sort(function(a,b) {
					var A = a.key.toLowerCase(),
					B = b.key.toLowerCase();

					if (A < B){
						return -1;
					}else if (A > B){
						return  1;
					}else{
						return 0;
					}
				});
			}

			for (var i = 0; i < data.length; i++) {
				buildParams(data[i].key, data[i].value, add, opts.attr);
			}
		}
		// Return the resulting serialization

		return (opts.no_replace_spaces ? s.join("&") : s.join("&").replace(r20, "+")); // fix 11764
	};
})();

// Cross-domain requests
// This object can be overridden to change who does cross domain requests
Request.xdr = function(args) {
	args = sanitizePSSessionQueryString(args);

	var defer = Promise.defer();
	args.timeout = (typeof args.timeout != "undefined" ? args.timeout : 0);
	args.options = (typeof(args.options) != "object")? {} : args.options;
	args.options.cors = (typeof(args.options.cors) != "boolean")? true : args.options.cors;

	// PSLIBCore.xsQuery
	if (args.type == "post" ||
		args.type == "put" ||
		args.type == "delete") {

		// Generic data set operation for retrieval later
		if (!args.url && PSLIBCore.appdata && PSLIBCore.appdata.config && PSLIBCore.appdata.config.homesite) {
			args.options.cors = false;

			args.url = PSLIBCore.appdata.config.homesite+"/get.php?res=SS_XS&op=set";
			args.data = {
				data: args.data
			}
		}

		if (!args.data) {
			args.data = {};
		}
		if (PSLIBCore.util.isDOMElement(args.data)) {
			var obj = document.createElement("input");
			PSLIBCore.attr(obj, 'name', '_method');
			PSLIBCore.attr(obj, 'type', 'hidden');
			PSLIBCore.attr(obj, 'value', args.type);
			args.data.appendChild(obj);
		}
		// Method not set
		else if (!args.data || !args.data["_method"]) {
			args.data._method = args.type;
		}

		PSLIBCore.xsQuery.post(
			args.url,
			args.data,
			{
				successFunc: function(response) {
					var ssid = (response && response.ssid)? response.ssid : null;

					if (response.result) {
						response = response.result;
					}
					if (response.status == 200 ||
						response.status == 201 ||
						response.status == 202) {
						defer.resolve({
							data: response.data,
							textStatus: "",
							xhr: {
								ssid: ssid,
								status: response.status
							}
						});
					}
					else if (response.status === undefined) {
						defer.resolve({
							data: response,
							textStatus: "",
							xhr: {
								ssid: ssid,
								status: 200
							}
						});
					}
					else {
						defer.reject({
							data: response.data,
							textStatus: "",
							xhr: {
								ssid: ssid,
								status: response.status
							}
						});
					}
				},
				failureFunc: function(response) {
					if (response.result) {
						response = response.result;
					}
					defer.reject({
						data: response.data,
						textStatus: "",
						xhr: {
							status: response.status
						}
					});
				}
			},
			PSLIBCore.util.extendObject({
				ssid: args.ssid,
				wwwFormDataFormat: true,
				timeout: args.timeout
			}, args.options)
		);
	}
	else if (args.type == "postonly") {
		PSLIBCore.xsQuery.request(
			"POSTONLY",
			args.url,
			{
				successFunc: function(response) {
					defer.resolve({
						textStatus: "",
						xhr: {
							status: 200
						}
					});
				},
				failureFunc: function(response) {
					defer.reject({
						textStatus: "",
						xhr: {
							status: response.status
						}
					});
				}
			},
			args.data,
			{
				wwwFormDataFormat: true
			}
		);
	}
	else {
		// Generic data set operation for retrieval later
		if (!args.url && args.ssid && PSLIBCore.appdata && PSLIBCore.appdata.config && PSLIBCore.appdata.config.homesite) {
			args.options.cors = false;

			args.url = PSLIBCore.appdata.config.homesite+"/get.php?res=SS_XS&op=get&ss_id="+args.ssid;
		}

		args.options.timeout = (typeof(args.timeout) != "undefined")? args.timeout : 0;

		// Include any query data with the url
		args.url = Request.getUrlWithQueryString(args.url, args.data);

		PSLIBCore.xsQuery.get(
			args.url,
			{
				successFunc: function(response) {
					if (response.result) {
						response = response.result;
					}
					if (response.status == 200 ||
						response.status == 201 ||
						response.status == 202) {
						defer.resolve({
							data: response.data,
							textStatus: "",
							xhr: {
								status: response.status
							}
						});
					}
					else {
						defer[(((response.status || 200) > 299)? "reject" : "resolve")]({
							data: response,
							textStatus: "",
							xhr: {
								status: (response.status || 200)
							}
						});
					}
				},
				failureFunc: function(response) {
					if (response.result) {
						response = response.result;
					}
					defer.reject({
						data: response,
						textStatus: "",
						xhr: {
							status: 500
						}
					});
				}
			},
			args.options
		);
	}
	return defer.promise;
};

// Regular ajax requests
Request.ajax = function(args) {
	args = sanitizePSSessionQueryString(args);

	if (args.soap) {
		var defer = Promise.defer();

		PSLIBCore.connection.callService(args.url, args.soap.action, args.soap.namespace, args.data, {
			successFunc: function(response) {
				var e, data;
				response.data = false;
				try {
					// we didnt expect these characters. remove them. hack for sharepoint web services issue.
					data = response.responseText.replace(/[\x00-\x1f]/g, " ");
					response.data = PSLIBCore.util.parseJson(data);
					if (response.responseText && response.responseText.length > 0 && !response.data) {
						PSLIBCore.util.remotelog("Request.ajax:"+response.responseText);
					}
				}
				catch (e) {
				}
				defer.resolve(response);
			},
			failureFunc: function(response) {
				response.data = PSLIBCore.util.parseJson(response.responseText);
				defer.reject(response);
			}
		});

		return defer.promise;
	}
	else {
		var defer = Promise.defer();
		// Required for post
		if (typeof(args.headers['Content-Type']) != "string") {
			args.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
		}

		PSLIBCore.connection.get(
			args.type,
			args.url,
			{
				successFunc: function(response) {
					if (response.status == 200 ||
						response.status == 201 ||
						response.status == 202) {

						var data;
						try {
							data = PSLIBCore.util.parseJson(response.responseText);
						} catch(e) {
						}

						defer.resolve({
							data: data,
							textStatus: "",
							xhr: {
								responseText: (response.responseText || ""),
								status: response.status
							}
						});
					}
					else if (response.status === undefined) {
						defer.resolve({
							data: response,
							textStatus: "",
							xhr: {
								responseText: (response.responseText || ""),
								status: 200
							}
						});
					}
					else {
						defer.reject({
							data: response.data,
							textStatus: "",
							xhr: {
								responseText: (response.responseText || ""),
								status: response.status
							}
						});
					}
				},
				failureFunc: function(response) {
					defer.reject({
						data: response,
						textStatus: "",
						xhr: {
							responseText: (response.responseText || ""),
							status: response.status
						}
					});
				},
				context: this
			},
			((typeof(args.data) == "string")? args.data : Request.param(args.data)),
			args.headers
		);

		return defer.promise;
	}
};

Request.dataToInputs = (function() {
	var g_toInputs = function(data, inputType, keyStack, inputStack, depth) {
		inputType = (typeof(inputType) == "undefined")? "hidden" : inputType;
		keyStack = (typeof(keyStack) == "undefined")? [] : keyStack;
		inputStack = (typeof(inputStack) == "undefined")? [] : inputStack;
		depth = (typeof(depth) == "undefined")? 0 : depth;

		// Seems like a reasonable depth to traverse
		if (depth > 100) {
			if (typeof(console) == "object" && typeof(console.error) === "function") {
				console.error("Request.dataToInputs hit max depth")
				console.error(data, inputType, keyStack, inputStack, depth);
			}
			return;
		}

		var dataType = (PSLIBCore.util.isArray(data)? "array" : typeof(data)), valType,
		type, val, keyStackCopy, keyStackCopy, name, $input, cleanArray,
		toInput = function(stack, val) {
			var name = "", elem;
			for(var i = 0; i < stack.length; i++) {
				if (i == 0) {
					name = stack[i];
				}
				else {
					name += "["+stack[i]+"]";
				}
			}
			elem = document.createElement("input");
			elem.type = inputType;
			elem.name = name;
			elem.value = val;

			return elem;
		};

		// Convert the DOM element to JSON
		if (PSLIBCore.util.isDOMElement(data)) {
			var inputs = (((data.tagName || "").toLowerCase() == "input")? [data] : PS$$("input,textarea,select", data));
			for (var i = 0; i < inputs.length; i++) {
				var selectedIndex = 0, isChecked;
				// Copy the node before moving, so the form doesn't jump around
				try {
					if (inputs[i].tagName.toLowerCase() == "input" &&
						(inputs[i].type == "radio" ||
						inputs[i].type == "checkbox")) {
						isChecked = inputs[i].checked;
					}
					else if (inputs[i].tagName.toLowerCase() == "select") {
						for(var j = 0; j < inputs[i].options.length; j++) {
							if (inputs[i].options[j].selected) {
								selectedIndex = j;
							}
						}
					}
					// Cloning node because type=file does not copy the value, must move
					elem = inputs[i].cloneNode(true);
					inputs[i].parentNode.insertBefore(elem, inputs[i]);
					if (inputs[i].type != "file") {
						elem.value = inputs[i].value;
					}

					if (elem.tagName.toLowerCase() == "input" &&
						(elem.type == "radio" ||
						elem.type == "checkbox")) {
						elem.checked = isChecked;
					}
				} catch(err) {
				}

				if (inputs[i].tagName.toLowerCase() == "input" &&
					(inputs[i].type == "radio" ||
					inputs[i].type == "checkbox")) {
					inputs[i].checked = isChecked;
				}
				else if (inputs[i].tagName.toLowerCase() == "select") {
					for(var j = 0; j < inputs[i].options.length; j++) {
						if (j == selectedIndex) {
							inputs[i].options[j].selected = true;
						}
					}
				}
				var stack = [];
				for(var j = 0; j < keyStack.length; j++) {
					stack.push(keyStack[j]);
				}
				// Prefer data-name (non-form-clobbering just incase in a clients form) over name
				var parts = PSLIBCore.attr(inputs[i], "data-name") || PSLIBCore.attr(inputs[i], "name");
				parts = parts.split(/\[/);

				for(var j = 0; j < parts.length; j++) {
					stack.push(parts[j].replace(/\]$/, ''));
				}

				var name = "";
				for(var j = 0; j < stack.length; j++) {
					if (j == 0) {
						name = stack[j];
					}
					else {
						name += "["+stack[j]+"]";
					}
				}
				PSLIBCore.attr(inputs[i], "name", name);
				inputStack.push(inputs[i]);
			}
		}
		else if ((dataType == "string" || dataType == "number")) {
			inputStack.push(toInput(keyStack, data));
		}
		else {
			for(var k in data) {
				keyStackCopy = keyStack.slice();
				name = "";
				val = data[k];
				type = (PSLIBCore.util.isArray(val)? "array" : typeof(val)),
				keyStackCopy.push(k);
				// We need to recurse this data to build a key
				if (PSLIBCore.util.isDOMElement(data[k])) {
					g_toInputs(data[k], inputType, keyStackCopy, inputStack, (depth+1));
				}
				else if (type == "object") {
					g_toInputs(data[k], inputType, keyStackCopy, inputStack, (depth+1));
				}
				else if (type == "array") {
					cleanArray = true;
					for(var i = 0; i < val.length; i++) {
						valType = (PSLIBCore.util.isArray(val[i])? "array" : typeof(val[i]));
						if (!(valType == "string" ||
						valType == "number")) {
							cleanArray = false;
						}
					}
					if (!cleanArray) {
						for(var i = 0; i < val.length; i++) {
							var keyStackCopy2 = keyStackCopy.slice();
							keyStackCopy2.push(i);
							g_toInputs(data[k][i], inputType, keyStackCopy2, inputStack, (depth+1));
						}
					}
					else {
						for(var i = 0; i < val.length; i++) {
							var keyStackCopy2 = keyStackCopy.slice();
							keyStackCopy2.push("");
							g_toInputs(data[k][i], inputType, keyStackCopy2, inputStack, (depth+1));
						}
					}
				}
				// We can construct an input from here
				else {
					inputStack.push(toInput(keyStackCopy, val));
				}
			}
		}
		return inputStack;
	};
	return function(data, type) {
		return g_toInputs(data, type);
	}
})();

export default Request;
